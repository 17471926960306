import detectEthereumProvider from '@metamask/detect-provider';
import * as Sentry from '@sentry/nextjs';
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import useLocalStorage from '@/hooks/use-local-storage';
import FeatureFlag from '@/utils/feature-flag';
import { GTM } from '@/utils/tag-manager';

import { useKlipContext } from './klip';

// This project id is revealed in the frontend, but it's not a security risk.
// Because it is restricted through the Origin allow list in WalletConnect Cloud.
const projectId = 'dc6d842e754d7a9e249e5bd0bbb90bcd';
const mainnet = {
  chainId: 8217,
  name: 'KLAYTN',
  currency: 'KLAY',
  explorerUrl: 'https://kaiascope.com/',
  rpcUrl: 'https://public-en.node.kaia.io',
};
const metadata = {
  name: 'Swapscanner',
  description:
    'Swapscanner is a decentralized exchange aggregator that finds the best prices across multiple DEXs.',
  url: 'https://swapscanner.io',
  icons: ['https://swapscanner.io/android-chrome-192x192.png'],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  themeVariables: {
    '--w3m-font-family':
      "Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;",
  },
  chainImages: { 8217: '/images/networks/klaytn.png' },
  featuredWalletIds: [
    '3c2c985c0adff6f46a0d0e466b3924ed8a059043882cd1944ad7f2adf697ed54', // klip
    '855481a23310c2bccf2a6134367449d61bd2f1c8793f929516c4f68a6aaace7a', // neopin
    'b956da9052132e3dabdcd78feb596d5194c99b7345d8c4bd7a47cabdcb69a25f', // ABC
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
    'a1f506a38f39b672b369bd13b68abbbd81f83a0489e6625f2bf12aa0389c22ae', // d'cent
  ],
  excludeWalletIds: [
    'c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2', // Kaikas
  ],
});

class WalletError extends Error {
  metadata: any;

  constructor(message: string, maybeError: Error | any) {
    super(message);

    this.name = this.constructor.name;

    if (maybeError instanceof Error) {
      this.cause = maybeError;
    } else if (!!maybeError) {
      this.metadata = maybeError;
    }
  }
}

const providerNames = {
  kaiaWallet: "Kaia Wallet / D'CENT",
  klip: 'Klip',
  dcent: "D'CENT Mobile",
  metamask: 'Metamask',
  walletConnect: 'WalletConnect',
} as const;

export const providerTypes = [
  {
    name: "Kaia Wallet / D'CENT",
    id: 'kaiaWallet',
    icon: '/images/kaia_wallet.png',
    install:
      'https://chromewebstore.google.com/detail/kaia-wallet/jblndlipeogpafnldhgmapagcccfchpi',
  },
  { name: 'Klip', id: 'klip', icon: '/images/klip.png', install: 'https://klipwallet.com' },
  {
    name: "D'CENT Mobile",
    id: 'dcent',
    icon: '/images/dcent.svg',
    install: 'https://store.dcentwallet.com/pages/dcent-wallet-app',
  },
  {
    name: 'Metamask',
    id: 'metamask',
    icon: '/images/metamask.svg',
    install: 'https://metamask.io/download.html',
  },
  {
    name: 'WalletConnect',
    id: 'walletConnect',
    icon: '/images/wallet_connect_selector.png',
    install: '',
  },
] as const;

export type WalletType = keyof typeof providerNames;
export type Wallet = {
  name?: string;
  account?: string | null;
  send: (transaction: any, spec?: any) => Promise<string>;
  call?: (transaction: any) => Promise<any>;
  getBalance?: (address: string) => Promise<any>;
  personalSign: (address: string, message: string) => Promise<any>;
  watchAsset?: (address: string, symbol: string, decimals: string, image: string) => Promise<any>;
  blockNumber?: () => Promise<any>;
} & (
  | {
      type: 'klip';
      estimateGas?: (transaction: any) => Promise<any>;
    }
  | {
      type: 'kaiaWallet' | 'dcent' | 'metamask';
      estimateGas: (transaction: any) => Promise<any>;
      upperBoundGasPrice: () => Promise<bigint>;
      gasPrice: () => Promise<bigint>;
      getTransactionReceiptStatus: (transactionHash: string) => Promise<boolean | null>;
    }
  | {
      type: 'walletConnect';
      metadata: { name: string | null; icon: string | null };
      estimateGas: (transaction: any) => Promise<any>;
      upperBoundGasPrice: () => Promise<bigint>;
      gasPrice: () => Promise<bigint>;
      getTransactionReceiptStatus: (transactionHash: string) => Promise<boolean | null>;
    }
);
export type WalletAvailability = Partial<Record<WalletType, boolean>>;

export const WalletContext = createContext<{
  wallet?: Wallet;
  setWallet: Dispatch<SetStateAction<Wallet | undefined>>;
  providerType: WalletType | null;
  setProviderType: (value: WalletType | null) => void;
  setKlipAccount: (value: string | null) => void;
  isProviderTypeAvailable?: (value: WalletType) => Promise<boolean>;
  walletAvailability: WalletAvailability;
  invalidator: number;
  updateInvalidator: () => void;
  walletNickname: string | null;
  setWalletNickname: Dispatch<SetStateAction<string | null>>;
}>({
  wallet: undefined,
  setWallet: () => {},
  providerType: null,
  setProviderType: () => {},
  setKlipAccount: () => {},
  walletAvailability: {},
  invalidator: -1,
  updateInvalidator: () => {},
  walletNickname: null,
  setWalletNickname: () => {},
});

type Props = {
  children?: React.ReactNode;
};

const toHexString = (str: string): string => {
  let hex = '';
  for (let i = 0; i < str.length; i++) hex += Number(str.charCodeAt(i)).toString(16);
  return '0x' + hex;
};

export const WalletContextProvider: React.FC<Props> = ({ children }) => {
  const [providerType, setProviderType, _setProviderType] = useLocalStorage<WalletType | null>(
    LOCAL_STORAGE_KEYS.walletType,
    null,
  );
  const [klipAccount, setKlipAccount] = useLocalStorage<string | null>(
    LOCAL_STORAGE_KEYS.klipAccount,
    null,
  );

  const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
  const [walletNickname, setWalletNickname] = useState<string | null>(null);
  const [walletAvailability, setWalletAvailability] = useState<WalletAvailability>({});
  const [invalidator, setInvalidator] = useState<number>(-1);

  const updateInvalidator = useCallback(() => {
    setInvalidator(Date.now());
  }, []);

  const { createRequest, cancelAll } = useKlipContext();

  if (typeof window !== 'undefined') {
    window.scnrWallet = wallet;
  }

  useEffect(() => {
    Sentry.setUser({
      id: 'wallet:' + wallet?.account,
      ip_address: '{{auto}}',
    });
  }, [wallet?.account]);

  const updateWallet = useCallback(
    (obj: (Partial<Wallet> & { type: WalletType }) | null) => {
      if (!obj) {
        setWallet(undefined);
        return;
      }

      // @ts-ignore
      setWallet((w) => {
        if (w?.type !== obj?.type) return w;
        return {
          ...w,
          name: providerNames[obj?.type],
          ...obj,
        };
      });

      GTM.dataLayer({
        dataLayer: {
          event: 'wallet_select',
          walletProvider: obj?.type ?? null,
          walletAddress: obj?.account ?? null,
        },
      });
      updateInvalidator();
    },
    [updateInvalidator],
  );

  const createHandleKaiaWalletAccountsChanged = useCallback(
    (type: WalletType) => (accounts: string[]) => {
      updateWallet({
        type,
        account: accounts[0]?.toLowerCase(),
      });
    },
    [updateWallet],
  );

  const handleMetamaskAccountsChanged = useCallback(
    (accounts: string[]) => {
      updateWallet({
        type: 'metamask',
        account: accounts[0]?.toLowerCase(),
      });
    },
    [updateWallet],
  );

  const handleMetamaskChainChanged = useCallback(
    (chainID: string) => {
      if (!(process.env.ENV === 'production' || process.env.ENV === 'next')) {
        if (chainID === '6512' || chainID === '0x1970') {
          return;
        }
      }
      if (chainID !== '8217' && chainID !== '0x2019') {
        setProviderType(null);
      }
    },
    [setProviderType],
  );

  useEffect(() => {
    if (!klipAccount) return;
    updateWallet({
      type: 'klip',
      account: klipAccount.toLowerCase(),
    });
  }, [klipAccount, updateWallet]);

  useEffect(() => {
    if (wallet?.type !== 'klip') return;

    // no need to fetch account since we already have it
    if (klipAccount) return;

    let terminate: () => void | undefined;
    let destroyed: boolean = false;

    (async () => {
      try {
        const { promise, cancel } = await createRequest({ type: 'auth', spec: { name: 'auth' } });

        promise
          .then((res: any) => {
            if (destroyed) return;
            setKlipAccount(res?.result?.klaytn_address);
          })
          .catch((err: Error) => {
            if (destroyed) return;

            console.error('klip', err);

            setProviderType(null);
          });

        terminate = cancel;

        if (destroyed) {
          cancel();
          return;
        }
      } catch (err) {
        console.error('klip', err);

        setProviderType(null);
      }
    })();

    return () => {
      if (terminate) {
        terminate();
      }
      destroyed = true;
    };
  }, [wallet?.type, klipAccount, createRequest, setKlipAccount, setProviderType]);

  const {
    address: wcAddress,
    chainId: wcChainId,
    isConnected: wcIsConnected,
  } = useWeb3ModalAccount();
  const { walletProvider: wcProvider } = useWeb3ModalProvider();

  useEffect(() => {
    if (!wallet) return;
    if (wallet.type !== providerType) {
      setWallet(undefined);
    }
  }, [wallet, providerType]);

  useEffect(() => {
    if (!FeatureFlag.isWalletConnectEnabled) {
      return;
    }

    if (!wcProvider) return;

    setProviderType('walletConnect');
  }, [wcProvider, setProviderType]);

  const wcMetadata = useMemo(() => {
    if (!FeatureFlag.isWalletConnectEnabled) {
      return;
    }

    if (!wcProvider || providerType !== 'walletConnect') return;

    const wcProviderTypedAny = wcProvider as any;

    if (!!wcProviderTypedAny?.isMetaMask) {
      // MetaMask extension
      return {
        name: 'MetaMask',
        icon: '/images/metamask.svg',
      };
    }

    if (!!wcProviderTypedAny?._kaikas) {
      // Kaia Wallet / D'CENT can be connected by Browser Wallet.
      return {
        name: "Kaia Wallet / D'CENT",
        icon: '/images/kaia_wallet.png',
      };
    }

    if (!!wcProviderTypedAny?.isCoinbaseWallet) {
      // Coinbase Wallet
      return {
        name: 'Coinbase Wallet',
        icon: '/images/coinbase_wallet.svg',
      };
    }

    /**
     * WalletConnect does not provide standardized information about which wallet it is connected to.
     * Therefore, each wallet may require fetching metadata in a different way.
     *
     * In most cases, excluding the predefined cases mentioned above, you can use "wcProviderTypedAny.signer.session.peer.metadata" to obtain metadata.
     *
     * To ensure safety, if "wcProviderTypedAny.signer.session.peer.metadata" is not available,
     * it is considered that the WalletConnect provider has not been found, and it should be handled accordingly.
     */
    if (!wcProviderTypedAny?.signer?.session?.peer?.metadata) {
      // WalletConnect session not found
      setProviderType(null);
      setWallet(undefined);
      return;
    }

    const {
      signer: {
        session: {
          peer: { metadata: wcMetadata },
        },
      },
    } = wcProviderTypedAny;

    // MetaMask Wallet has no icon
    if (wcMetadata.name === 'MetaMask Wallet') {
      return {
        name: 'MetaMask',
        icon: '/images/metamask.svg',
      };
    }

    return {
      name: wcMetadata.name,
      icon: wcMetadata.icons?.[0] ?? '/images/wallet_connect.webp',
    };
  }, [wcProvider, providerType, setProviderType]);

  useEffect(() => {
    let destroyed = false;

    switch (providerType) {
      case 'dcent':
      case 'kaiaWallet':
        setWallet({
          type: providerType,
          watchAsset: async (address, symbol, decimals, image) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'wallet_watchAsset',
                  params: {
                    type: 'ERC20',
                    options: {
                      address,
                      symbol,
                      decimals,
                      image,
                    },
                  },
                  id: Date.now(),
                },
                (err, added) => {
                  if (err) {
                    reject(new WalletError('error on wallet_watchAsset', err));
                    return;
                  }

                  resolve(added);
                },
              );
            });
          },
          send: async (tx: any) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_sendTransaction',
                  params: [tx],
                  from: tx.from,
                },
                (err, something) => {
                  if (err) {
                    reject(new WalletError('error on klay_sendTransaction', err));
                    return;
                  }

                  if (!something?.result) {
                    reject(new Error('Transaction cancelled'));
                    return;
                  }

                  resolve(something?.result);
                },
              );
            });
          },
          call: async (tx: any) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_call',
                  params: [tx, 'latest'],
                  from: tx.from,
                },
                (err, something) => {
                  if (err) {
                    reject(new WalletError('error on klay_call', err));
                    return;
                  }

                  if (!something?.result) {
                    reject(new Error('Transaction cancelled'));
                    return;
                  }

                  resolve(something?.result);
                },
              );
            });
          },
          estimateGas: async (tx: any) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_estimateGas',
                  params: [tx],
                  from: tx.from,
                },
                (err, something) => {
                  if (err) {
                    reject(new WalletError('error on klay_estimateGas', err));
                    return;
                  }

                  if (!something?.result) {
                    reject(new Error('Transaction cancelled'));
                    return;
                  }

                  resolve(BigInt(something?.result));
                },
              );
            });
          },
          getBalance: async (address) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_getBalance',
                  params: [address, 'latest'],
                },
                (err, something) => {
                  if (err) {
                    reject(new WalletError('error on klay_getBalance', err));
                    return;
                  }

                  if (!something?.result) {
                    reject(new Error('Transaction cancelled'));
                    return;
                  }

                  resolve(something?.result);
                },
              );
            });
          },
          blockNumber: async () => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_blockNumber',
                  params: [],
                },
                (err, blockNumberMaybeHex) => {
                  if (err) {
                    reject(new WalletError('error on klay_blockNumber', err));
                    return;
                  }

                  if (!blockNumberMaybeHex) {
                    reject(new Error('Empty block number'));
                    return;
                  }

                  resolve(BigInt(blockNumberMaybeHex));
                },
              );
            });
          },
          upperBoundGasPrice: async () => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_upperBoundGasPrice',
                  params: [],
                },
                (err, gasPriceMaybeHex) => {
                  if (err) {
                    reject(new WalletError('error on klay_upperBoundGasPrice', err));
                    return;
                  }

                  if (!gasPriceMaybeHex?.result) {
                    reject(new Error('Empty upper bound gas price'));
                    return;
                  }

                  resolve(BigInt(gasPriceMaybeHex.result));
                },
              );
            });
          },
          personalSign: async (address, message) => {
            return new Promise(async (resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_sign',
                  params: [address, toHexString(message)],
                  // params: [address, message],
                },
                (err, something) => {
                  if (err) {
                    reject(new WalletError('error on klay_sign', err));
                    return;
                  }

                  if (!something?.result) {
                    reject(new Error('Transaction cancelled'));
                    return;
                  }

                  resolve(something?.result);
                },
              );
            });
          },
          gasPrice: async () => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_gasPrice',
                  params: [],
                },
                (err, gasPriceMaybeHex) => {
                  if (err) {
                    reject(new WalletError('error on klay_gasPrice', err));
                    return;
                  }

                  if (!gasPriceMaybeHex?.result) {
                    reject(new Error('Empty gas price'));
                    return;
                  }

                  resolve(BigInt(gasPriceMaybeHex.result));
                },
              );
            });
          },
          getTransactionReceiptStatus: async (txHash: string) => {
            return new Promise((resolve, reject) => {
              if (destroyed) {
                return reject(new Error('destroyed'));
              }

              window.klaytn!.sendAsync(
                {
                  method: 'klay_getTransactionReceipt',
                  params: [txHash],
                },
                (err, res) => {
                  if (err) {
                    reject(new WalletError('error on klay_getTransactionReceipt', err));
                    return;
                  }

                  if (!res?.result?.status) {
                    resolve(null);
                    return;
                  }

                  resolve(BigInt(res.result.status) === 1n);
                },
              );
            });
          },
        });

        const handler = createHandleKaiaWalletAccountsChanged(providerType);

        const setup = async () => {
          try {
            const accounts = await window.klaytn!.enable();
            if (destroyed) return;
            window.klaytn!.on('accountsChanged', handler);
            handler(accounts);
          } catch (err) {
            if (destroyed) return;
            console.error('kaia wallet', err);
            setProviderType(null);
          }
        };

        let timeout: NodeJS.Timeout;

        if (providerType === 'dcent') {
          window.addEventListener('klaytn#initialized', setup);
          timeout = setTimeout(setup, 1111);
        } else {
          setup();
        }

        return () => {
          destroyed = true;
          if (providerType === 'dcent') {
            window.removeEventListener('klaytn#initialized', setup);
          }
          window.klaytn!.off('accountsChanged', handler);
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      case 'klip':
        setWallet({
          type: 'klip',
          account: klipAccount?.toLowerCase(),
          send: async (klipTx, spec) => {
            let method = 'execute_contract';
            if (!klipTx.abi && !klipTx.params) {
              // send native, while truncating last 12 digits
              method = 'send_klay';
              const interimAmount = klipTx.value.substring(0, klipTx.value.length - 12);
              const amount =
                (interimAmount.substring(0, interimAmount.length - 6) || '0') +
                '.' +
                interimAmount.substring(interimAmount.length - 6);
              klipTx.amount = amount.replace(/0+$/, '').replace(/\.$/, '');
              delete klipTx.value;
            }

            try {
              const { promise } = await createRequest({ type: method, transaction: klipTx, spec });
              const res = await promise;
              const {
                result: { status, tx_hash },
              } = res;
              if (status !== 'success') {
                console.error('klip error', res);
                throw new Error('non-success status');
              }
              return tx_hash;
            } catch (err) {
              console.error('klip error', err);
              throw new Error('non-success status');
            }
          },
          personalSign: async (address, message) => {
            let method = 'sign_message';
            const name = 'signMessage';
            const body = {
              value: message,
              from: address,
            };
            const { promise } = await createRequest({
              type: method,
              message: body,
              spec: { name },
            });
            const res = await promise;
            const {
              status,
              result: { signature },
            } = res;
            if (status !== 'completed') {
              console.error('klip error', res);
              throw new Error('non-success status');
            }
            return signature;
          },
        });
        // fire event to trigger klip wallet selection
        // `updateWallet` is not fired here
        GTM.dataLayer({
          dataLayer: {
            event: 'wallet_select',
            walletProvider: 'klip',
            walletAddress: klipAccount?.toLowerCase(),
          },
        });
        break;
      case 'walletConnect': {
        if (
          !wcProvider ||
          !wcMetadata ||
          destroyed ||
          !wcIsConnected ||
          !wcChainId ||
          wcChainId !== 8217
        ) {
          return;
        }

        const provider = new ethers.BrowserProvider(wcProvider);

        setWallet({
          type: 'walletConnect',
          metadata: wcMetadata,
          account: wcAddress?.toLowerCase(),
          watchAsset: async (address, symbol, decimals, image) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.send('wallet_watchAsset', {
              type: 'ERC20',
              options: { address, symbol, decimals, image },
            });
          },
          send: async ({ type, ...tx }: any) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.send('eth_sendTransaction', [tx]);
          },
          estimateGas: async (tx) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(await provider.send('eth_estimateGas', [tx]));
          },
          call: async (tx: any) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.send('eth_call', [tx, 'latest']);
          },
          getBalance: async (address) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.send('eth_getBalance', [address, 'latest']);
          },
          blockNumber: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(await provider.send('eth_blockNumber', []));
          },
          personalSign: async (address, message) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.send('personal_sign', [message, address]);
          },
          upperBoundGasPrice: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(await provider.send('eth_upperBoundGasPrice', []));
          },
          gasPrice: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(await provider.send('eth_gasPrice', []));
          },
          getTransactionReceiptStatus: async (txHash) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            const receipt = await provider.send('eth_getTransactionReceipt', [txHash]);

            if (!receipt) return null;
            if (!receipt?.status) return null;
            return BigInt(receipt.status) === BigInt(1);
          },
        });

        return () => {
          destroyed = true;
        };
      }
      case 'metamask':
        let provider: any;
        (async () => {
          provider = await detectEthereumProvider();
          if (destroyed) return;

          try {
            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x2019' }],
            });
            if (destroyed) return;
          } catch (switchError) {
            if (destroyed) return;
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              try {
                await provider.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: '0x2019',
                      chainName: 'Kaia (KAS)',
                      nativeCurrency: {
                        name: 'Kaia',
                        symbol: 'KLAY',
                        decimals: 18,
                      },
                      rpcUrls: ['https://public-node-api.klaytnapi.com/v1/cypress'],
                      blockExplorerUrls: ['https://kaiascope.com'],
                      iconUrls: [
                        'https://api.swapscanner.io/api/tokens/0x0000000000000000000000000000000000000000/icon',
                      ],
                    },
                  ],
                });

                if (destroyed) return;
              } catch (addError) {
                if (destroyed) return;
                console.error(addError);
                // handle "add" error
              }
            }
            // handle other "switch" errors
            console.error(switchError);
          }

          provider.on('accountsChanged', handleMetamaskAccountsChanged);
          provider.on('chainChanged', handleMetamaskChainChanged);

          try {
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            if (destroyed) return;
            handleMetamaskAccountsChanged(accounts);
          } catch (err) {
            console.error('metamask', 'eth_requestAccounts', err);
          }

          try {
            const chainID = await provider.request({ method: 'eth_chainId' });
            if (destroyed) return;
            handleMetamaskChainChanged(chainID);
          } catch (err) {
            console.error('metamask', 'eth_chainId', err);
          }
        })();

        setWallet({
          type: 'metamask',
          watchAsset: async (address, symbol, decimals, image) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address,
                  symbol,
                  decimals,
                  image,
                },
              },
              id: Date.now(),
            });
          },
          send: async ({ type, ...tx }) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.request({
              method: 'eth_sendTransaction',
              params: [tx],
              from: tx.from,
            });
          },
          estimateGas: async (tx) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(
              await provider.request({
                method: 'eth_estimateGas',
                params: [tx],
              }),
            );
          },
          call: async (tx: any) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.request({
              method: 'eth_call',
              params: [tx, 'latest'],
              from: tx.from,
            });
          },
          getBalance: async (address) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return provider.request({
              method: 'eth_getBalance',
              params: [address, 'latest'],
            });
          },
          blockNumber: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(
              await provider.request({
                method: 'eth_blockNumber',
              }),
            );
          },
          personalSign: async (address, message) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return await provider.request({
              method: 'personal_sign',
              params: [message, address],
            });
          },
          upperBoundGasPrice: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(
              await provider.request({
                method: 'eth_upperBoundGasPrice',
                params: [],
              }),
            );
          },
          gasPrice: async () => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            return BigInt(
              await provider.request({
                method: 'eth_gasPrice',
                params: [],
              }),
            );
          },
          getTransactionReceiptStatus: async (txHash) => {
            if (destroyed || !provider) {
              throw new Error('destroyed');
            }

            const receipt = await provider.request({
              method: 'eth_getTransactionReceipt',
              params: [txHash],
            });
            if (!receipt) return null;
            if (!receipt?.status) return null;
            return BigInt(receipt.status) === BigInt(1);
          },
        });

        return () => {
          destroyed = true;
          if (provider) {
            provider.removeListener('accountsChanged', handleMetamaskAccountsChanged);
            provider.removeListener('chainChanged', handleMetamaskChainChanged);
          }
        };
      default:
        if (providerType !== null) {
          setProviderType(null);
        }
        cancelAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerType,
    cancelAll,
    createHandleKaiaWalletAccountsChanged,
    createRequest,
    handleMetamaskAccountsChanged,
    // handleMetamaskChainChanged,
    klipAccount,
    // setProviderType,
    wcProvider,
    wcMetadata,
    wcIsConnected,
    wcChainId,
  ]);

  const isProviderTypeAvailable = async (type: WalletType): Promise<boolean> => {
    switch (type) {
      case 'dcent':
        return (
          typeof window !== 'undefined' &&
          typeof window?.klaytn !== 'undefined' &&
          window?.klaytn?.isDcentWallet
        );
      case 'kaiaWallet':
        return (
          typeof window !== 'undefined' &&
          typeof window?.klaytn !== 'undefined' &&
          !window?.klaytn?.isDcentWallet
        );
      case 'klip':
        return true;
      case 'metamask':
        return (
          (!!(await detectEthereumProvider()) &&
            typeof window !== 'undefined' &&
            typeof window?.ethereum !== 'undefined' &&
            window?.ethereum?.isMetaMask) ??
          false
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    let destroyed = false;

    const work = () => {
      (async () => {
        const availability = {
          dcent: await isProviderTypeAvailable('dcent'),
          kaiaWallet: await isProviderTypeAvailable('kaiaWallet'),
          klip: await isProviderTypeAvailable('klip'),
          metamask: await isProviderTypeAvailable('metamask'),
          walletConnect: true,
        };

        if (destroyed) return;

        setWalletAvailability(availability);
      })();
    };

    // allowing some time for extensions to complete script injection
    const timeout = setTimeout(work, 0);

    work();

    const listener = () => {
      work();
    };

    window.addEventListener('klaytn#initialized', listener);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      window.removeEventListener('klaytn#initialized', listener);
    };
  }, [setWalletAvailability]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        setWallet,
        providerType,
        setProviderType,
        setKlipAccount,
        isProviderTypeAvailable,
        walletAvailability,
        invalidator,
        updateInvalidator,
        walletNickname,
        setWalletNickname,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);

  if (context === undefined) {
    throw new Error(`useWalletContext must be used within a WalletContextProvider`);
  }

  return context;
};
